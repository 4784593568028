import React, { useEffect, useState } from 'react';
import './truehack.scss';
import { ReactComponent as BgShape } from './assets/bg-shape.svg';
import Gif from './assets/Countdown-CorniceV1.gif';

const HACKATHON_START_DATE = '10/03/2024 15:00:00';
// const HACKATHON_END_DATE = '10/04/2024 15:00:00';
const HACKATHON_END_DATE = '10/03/2024 15:00:00';

// const HACKATHON_START_DATE = '09/04/2024 15:19:00';
// const HACKATHON_END_DATE = '09/04/2024 15:19:10';

const HACKATHON_START_TIME = new Date(HACKATHON_START_DATE).getTime();
const HACKATHON_END_TIME = new Date(HACKATHON_END_DATE).getTime();

/** -----------------------------------------------------------------------------
 * Util to calculate remaining time
 * ------------------------------------------------------------------------------- */

function calculateTimeLeft({ countDownTime, currentTime }) {
  const difference = countDownTime - currentTime;
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  let timeLeftString = {
    days: timeLeft.days.toString().padStart(2, 0),
    hours: timeLeft.hours.toString().padStart(2, 0),
    minutes: timeLeft.minutes.toString().padStart(2, 0),
    seconds: timeLeft.seconds.toString().padStart(2, 0),
  };
  return timeLeftString;
}

/** -----------------------------------------------------------------------------
 * Time component
 * ------------------------------------------------------------------------------- */

function Time({ time, label }) {
  return (
    <>
      {time && <span className="countdown-num">{time}</span>}
      {label && <span className="countdown-info">{label}</span>}
    </>
  );
}

/** -----------------------------------------------------------------------------
 * Timer component
 * ------------------------------------------------------------------------------- */

function Timer() {
  const [timerState, setTimerState] = useState({
    timeLeftString: undefined,
    hasHackathonStarted: false,
    hasHackathonEnded: false,
    totalTimeLeft: undefined,
    totalPercentage: undefined,
  });

  const { hasHackathonEnded, timeLeftString } = timerState || {};

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentTime = new Date().getTime();

      const hasStarted = currentTime > HACKATHON_START_TIME;
      const hasEnded = currentTime > HACKATHON_END_TIME;

      /** Date that will be passed to the countdown component */
      const countDownTime = hasStarted
        ? HACKATHON_END_TIME
        : HACKATHON_START_TIME;

      const newTimeLeft = calculateTimeLeft({ countDownTime, currentTime });
      const totalTime =
        Math.abs(HACKATHON_START_TIME - HACKATHON_END_TIME) / 1000;
      // const diffTime = Math.abs(new Date() / HACKATHON_END_DATE) * 1000;
      const timeBetweenStartAndEnd = HACKATHON_END_TIME - HACKATHON_START_TIME;
      const timeBetweenStartAndToday = currentTime - HACKATHON_START_TIME;

      const totalTimePercentage = +Math.round(
        (timeBetweenStartAndToday / timeBetweenStartAndEnd) * 100,
      );

      setTimerState({
        timeLeftString: newTimeLeft,
        hasHackathonStarted: hasStarted,
        hasHackathonEnded: hasEnded,
        totalTimeLeft: totalTime,
        totalPercentage: totalTimePercentage,
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  });

  /** Timer is running */
  if (timeLeftString && !hasHackathonEnded) {
    return (
      <div className="countdown-container">
        <div className="header">The clock is ticking</div>
        <div className="countdown-clock">
          <div className="countdown-time">
            <div className="time">
              <Time time={timeLeftString.days} label="Days" />
            </div>
            <span className="splitter">:</span>
            <div className="time">
              <Time time={timeLeftString.hours} label="Hours" />
            </div>
            <span className="splitter">:</span>
            <div className="time">
              <Time time={timeLeftString.minutes} label="Minutes" />
            </div>
            <span className="splitter">:</span>
            <div className="time">
              <Time time={timeLeftString.seconds} label="Seconds" />
            </div>
          </div>
        </div>
        {/* <div className="footer">Until the cursed hackathon</div> */}
      </div>
    );
  }

  /** Time is up */
  if (hasHackathonEnded) {
    return (
      <>
        <div className="countdown-container">
          <div className="header__times-up">It's beginning...</div>
          {/* <div className="header__times-up">Time's up</div> */}
        </div>
      </>
    );
  }

  /** Initialising */
  return (
    <div className="countdown-clock">
      <span className="countdown__times-up">Starting!</span>
    </div>
  );
}

/** -----------------------------------------------------------------------------
 * App
 * ------------------------------------------------------------------------------- */

export default function App() {
  return (
    <div className="truehack-container">
      {/* Countdown component */}
      <section id="countdown-section">
        <div className="container">
          <BgShape />
          <img src={Gif} alt="slime" />
          <div className="countdown">
            <Timer />
          </div>
        </div>
      </section>
    </div>
  );
}
